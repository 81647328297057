import FeatureAlertsButton from "../components/FeatureAlertsButton";

const Explain = () => {

    return(
    <div className="centered-text-container">
      <div className="centered-text">
        <span
          style={{
            fontSize: '14pt',
            fontWeight: 'bold',
          }}>
            Discuss papers with ChatGPT<br />
            
          [Coming Soon]</span>
        <br />
        <br />
        <span style = {{color: '#b3c1cf'}}>
          I am most excited about building this section. <br />
          This is where you will be able to have GPT-4 explain any part of a given paper and have a conversation about it.
        </span>
        <br /><br />Come back soon or sign up for

        <br />
             <FeatureAlertsButton />
             <br />
             <span className="muted"> I only send a very occasional email to announce new features. <br />No spam, I'm a human being too! :) </span>
        
      </div>
    </div>
   );
  };
export default Explain;