
import StyledButton from "../components/StyledButton";

const ContactMe = () => {

    return(
    <div className="centered-text-container">
      <div className="centered-text">
        If you wish to contact me for any reason, you are more than welcome. <br />You have two options:<br/>
        hi-AT-supersymmetry.ai<br/>
         <a href="https://www.linkedin.com/in/anyapetrova/">Linkedin</a>
      </div>
    </div>
   );
  };

export default ContactMe;