// import Accordion from 'react-bootstrap/Accordion';
import { Accordion, AccordionItem, Col, Container, Row } from "react-bootstrap";
import '../App.css';
import { Component } from "react";


function AboutProject() {
  return (

<>

    <Container>
      <Row>
        <Col md={12}>
        


            <Accordion defaultActiveKey="3"
            style ={{
              paddingTop: '30pt',
              textAlign: 'left',
            }}
            dark
            >

          <Accordion.Item eventKey="3">
            <Accordion.Header>Who is behind this project?</Accordion.Header>
            {/* <Accordion.Body style={{ backgroundColor: 'cornsilk'}}> */}
              <Accordion.Body >  

            <p>

            Glad you asked. Hi, My name is Anya Petrova, I am a Software Engineer living in Bay Area, CA. 
            I am originally from Moscow, Russia. 
            </p>
            <p>

            Although I am a Software Engineer I am also very passionate about Physics. 
            For years now I have been trying to stay up to date with some Physics topics of interest, 
            but I found it extremely frustrating that information and research are spread out all over and it's hard to keep track of and synthesize information. 
            I have decided to start building a tool that will help me (and hopefully other students and researchers) not only stay on top of research but also better understand, 
            summarize, and synthesize the research and science behind all that. The current wave of emerging AI tools such as GPT is perfect for building such a tool. 
            </p>
            <p>

            What you see right now is just the beginning - I have a very exciting vision of what this project will become!</p>
            <p>
            I invite you to <a href="https://dashboard.mailerlite.com/forms/429223/87391058786256118/share" target="_blank">sign-up for my Feature Release Alerts</a> - 
            a once-per-week email update on new features and improvements. <br />
            If you have any questions, ideas, or advice or wish to support the project  (I am currently looking for grants to fund the project), please feel free to reach out to me either at hi[at]supersymmetry.ai or on <a href="https://www.linkedin.com/in/anyapetrova/" target="_blank">LinkedIn</a> 
            </p>
            <p>
            Thanks so much for your interest in this project and I hope you will find it useful!
            </p>
            Anya

              {/* I am, however (as of yet) just one person, and yes, I still have a full time job, but I plan on building this as fast as possible and If you would consider supporting this porject, it will help get the project built much faster.  */}
          
              
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="0">
            <Accordion.Header>About Supersymmetry</Accordion.Header>
            {/* <Accordion.Body style={{ backgroundColor: 'cornsilk'}}> */}
              <Accordion.Body >  
              
              <p>

              The goal of this project is to build a search engine for scientific research with the use of GPT-4 and a concept of semantic search. 
              </p>
              <p>

              The current version of the project is built on top of the ArXiv data and hence is limited in a way by both the scope of topics covered as well as some other factors 
              (not all published papers are posted on ArXiv and those that are posted are not all peer-reviewed) Because of these limitations, Supersymmetry will be a Proof of Concept project 
              and will lay down the architecture foundation, which will become a base for future projects that will go beyond the ArXiv dataset.
              </p> </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>What is Semantic Search and how does it work?</Accordion.Header>
            {/* <Accordion.Body style={{ backgroundColor: 'cornsilk'}}> */}
            <Accordion.Body >  
              <p>

              Semantic Search does exactly what it sounds like: it finds matches based on the "semantic meaning" of text and not on the comparison of actual "words" you enter. 
              </p>
              <p>

              It is based on the same technique which allows modern AI tools (like GPT-4) to "understand" the meaning of the text. 
              Here is the process: a text, in our case, the abstract of a paper, is being given to GPT which encodes it into a multidimensional vector (1536 dimensions) 
              representing the "semantic meaning" of that abstract. The supersymmetry dataset has vectors of all 2Mil+ paper abstracts. 
              The resulting set of vectors has the following property: the more any two vectors are aligned, 
              the closer the "semantic meaning" of the original texts they represent. We can take a dot product between two vectors or compute a cosine similarity to get the actual "similarity distance". 
              In this project, I am using cosine similarity, as it has an additional handy property of always being in the 0-1 range, 
              which I then convert to a percentage value (Which is the number you see in the top right corner of all search result cards).
              </p>
            </Accordion.Body>
          </Accordion.Item> 

          <Accordion.Item eventKey="2">
            <Accordion.Header>Which research topics are covered?</Accordion.Header>
            {/* <Accordion.Body style={{ backgroundColor: 'cornsilk'}}> */}
            <Accordion.Body >  
              Supersymmetry is based on all papers published on <a href='https://arxiv.org/' target="_blank">ArXiv</a> website, which means it covers ~2.3 Million pre-print papers spanning the following topics: <br />
            Physics, Mathematics, Computer Science, Quantitative Biology, Quantitative Finance, Statistics, Electrical Engineering and Systems Science, and Economics. 
            It is important to note that the presence of any paper on the engine does not meet that it has been peer-reviewed and/or published in any scientific journal 
            as many papers that make it into ArXiv do not undergo a vetting process that establishes such credentials.<br /> 
            <b>I do plan on designing a way for creating a search filter that will allow for the narrowing of search results to confirmed journal publications only. </b>
            </Accordion.Body>
          </Accordion.Item>

          
          
        </Accordion>


  
        </Col>
      </Row>
    </Container>

</>
  );
}

export default AboutProject;