import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/ToggleComponent.css';

import React, { useState, useEffect, useRef} from 'react';
import { Container } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Articles from '../components/Articles';
import ToggleContent from '../components/ToggleContent';
import PillButton from '../elements/PillButton'
import Pill from '../elements/Pill'

import Explainer from '../elements/Explainer'
import DataSpinner from '../components/DataSpinner';
import FeatureAlertsButton from "../components/FeatureAlertsButton";

import { API_URL } from '../common/Api';

import { useLocation } from 'react-router-dom';

import cats_latest from './_cats_latest.json'

import { useNavigate } from "react-router-dom";
import StyledButton from './StyledButton';
import './styles/StyledButton.css';
import { Button } from 'react-bootstrap';
// import ClassicSearch from './ClassicSearch';
import ClassicSearchForm from './ClassicSearchForm';
import SemanticSearchForm from './SemanticSearchFrom';
import Pagination from './Pagination';
import Intro4 from './Intro4';

import ImageOverlay from './ImageOverlay';
import { BsSignTurnLeft, BsTruckFlatbed } from 'react-icons/bs';
import Toggle from 'react-toggle'


// import { Col, Row, Container } from 'react-bootstrap';
import './styles/Intro4.css';
import chatGpt from '../elements/chatGpt.png'


import { AiOutlineFileSearch } from "react-icons/ai"
import { BsCalendarRange } from "react-icons/bs";
import { RiImageEditLine } from "react-icons/ri";
import { BsBookmarkStarFill } from "react-icons/bs";

const Latest = ({inboundAbstract}) => {

    const perPageSetting = 12; 

    const [isChecked, setIsChecked] = useState(false);

    const [articles, setArticles] = useState([])
    const [searchMode, setSearchMode] = useState("browse")
    const [isLoading, setIsLoading] = useState(false)

    const [groupSelected, setGroupSelected] = useState("phys")
    const [catSelected, setCatSelected] = useState("")

    const [start, setStart] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    
    const [title, setTitle] = useState('')
    const [abstract, setAbstract] = useState('')
    const [semantic, setSemantic] = useState('')

    const [overlayImages, setOverlayImages] = useState([])
    const [showOverlay, setShowOverlay] = useState(false)

    const [showImages, setShowImages] = useState(true);

    const [values, setValues] = useState([2007, 2023]);

    // const [showOverlay, setShowOverlay] = useState(false)

    console.log(">>>>> ")
    // console.log(articles)
    // let location = useLocation();
    console.log(inboundAbstract)

    // Minimize controls 
    const [isMinimized, setIsMinimized] = useState(false);
    const [maxHeight, setMaxHeight] = useState(null);
    const contentRef = useRef(null);

    const [isMinimized2, setIsMinimized2] = useState(true);
    const [maxHeight2, setMaxHeight2] = useState(null);
    
    
    const paddingSmall = 0
    const [searchPadding, setSearchPadding] = useState(paddingSmall);

    const contentRef2 = useRef(null);

    const padding = 0

    const toggleMinimize2 = () => {
        
        if (isMinimized && isMinimized2) { setSearchPadding(padding) }
        else { setSearchPadding(paddingSmall) }

        setIsMinimized2(!isMinimized2);
    };

    const toggleMinimize = () => {
        
        if (!isMinimized && !isMinimized2) { setSearchPadding(padding) }
        else { setSearchPadding(paddingSmall) }

        setIsMinimized(!isMinimized);
    };

    useEffect(() => {
        if (contentRef.current) {
            setMaxHeight(isMinimized ? '0' : `${contentRef.current.scrollHeight}px`);
        }
    }, [isMinimized]);

    useEffect(() => {
        if (contentRef2.current) {
            setMaxHeight2(isMinimized2 ? '0' : `${contentRef2.current.scrollHeight + searchPadding}px`);
        }
    }, [isMinimized2, isMinimized]);

    // --- 


    const MAX_RETRIES = 3;
    async function getDataWithRetry(url, retryCount = 0) {
        // console.log("> getDataWithRetry()")
        try {
            // console.log("trying to fetch.. ")
            const res = await fetch(url)
            if (!res.ok) { // res.ok returns false if the response status is an error (i.e., not in the range 200-299)
                // throw new Error(`HTTP error! status: ${res.status}`);
                if (res.status === 500) { // check if status is 500

                    if (retryCount >= MAX_RETRIES) { // if retryCount is greater than or equal to MAX_RETRIES, throw error
                        // console.log("Max Retries exeeded!")
                        throw new Error(`HTTP error! status: ${res.status}`);
                    }
                    // console.log(`RETRYING.. : ${retryCount}`) // TODO: consolidate errors 
                    // wait for 2^retryCount * 100 milliseconds (exponential backoff)
                    await new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount) * 100));
                    return getDataWithRetry(url, retryCount + 1); // increment retryCount and make the request again
                }}
            return res
        } catch (error) {
            console.log(`> ERROR: ${error}`) // TODO: consolidate errors 
        }
    }
    async function fetchData(fetch_url) {
        // window.scrollTo(0,0);
        try {
            setIsLoading(true) // turn on SPINNER
            // console.log("[Articles] >> fetchData()")
            console.log(`>> fetch_url: ${fetch_url}`)
            // const res = await fetch(fetch_url)
            const res = await getDataWithRetry(fetch_url)
            const result = await res.json()

            const data = await result['data']
            const res_count = await result['total_count']
            setTotalCount(res_count)

            // console.log(">> result")
            // console.log(result)
            // console.log(">> data:")
            // console.log(data)
            // console.log(">> count:")
            // console.log(res_count)

            setArticles(Object.values(data))
        } catch (error) {
            console.log(`ERROR: ${error}`) // TODO: consolidate errors
        }
        setIsLoading(false)
    }



    

    function findSimilarId(id) {
        const topK = 20

        console.log("[PageBrowse] >> handleFindSimilar()")
        console.log(id)
        // setTotalCount(0)  // This will be resetting the pagination
        const fetch_url = `${API_URL}/superfind?id=${id}&topk=${topK}`
        fetchData(fetch_url)
    }
    
    function handleFindSimilar(e) {
        findSimilarId(e.target.id)
    }

    let navigate = useNavigate();
    
    function getAbstractById(xid) {
        for (let i = 0; i < articles.length; i++) {
            if (articles[i].xid === xid) {
                return articles[i].abstract;
            }
        }
        // If the given "id" is not found in the list, you can choose to return null or any other appropriate value.
        return null;
    }




    function findSimilarAbstract(abs) {
        const topK = 20

        // console.log("[PageBrowse] >> handleFindSimilar()")
        // console.log(id)
        // setTotalCount(0)  // This will be resetting the pagination
        const fetch_url = `${API_URL}/supersearch?prompt=${abs}`
        fetchData(fetch_url)
        // fetchData();
    }

    // Get a reference to the element you want to scroll to
    const targetElement = document.getElementById("scrollTo");

    function scroll() {
        targetElement.scrollIntoView({
            behavior: 'smooth', // Scroll with smooth animation
            block: 'start',     // Align the top of the element to the top of the viewport
            inline: 'nearest'   // Align the nearest edge of the element to the center of the viewport horizontally
        });
    }


    // TODO: finish this --- need to move this to pagination but for some reason had some issues
    function paginationNavigate() {
        scroll()
        console.log("[paginationNavigate] >> paginationNavigate()")
        // // window.scrollTo(0,0);
        // setStart(new_start) 
        // loadCategory(new_start, "")
        setIsMinimized(true)
        setIsMinimized2(true)
    }

    function handleFindSimilarAbstract(e) {
        setSearchMode("similar")
        let id = e.target.id
        let target = getAbstractById(id)
        console.log("**** handleFindSimilarAbstract *** ")
        console.log(location)
        console.log(id)
        console.log(target)

        setIsMinimized(true)
        setIsMinimized2(true)

        if (location.pathname == "/") {
            console.log("---- redirecting to /search")
            navigate("/search", { state: { abstract: target } });
        } else {
            console.log("---- findSimilarAbstract()")
            findSimilarAbstract(target)
        }
    }
    
    function loadCategory(page, group) {
        scroll()
        console.log("LOADING...")
        // if (group == "") {
        //     group = groupSelected
        // }

        if (catSelected == "") {
            group = groupSelected
        } else {
            group = catSelected
        }
    }
    
    function handleShowImagesToggle(e) {
        setShowImages(!showImages)
        console.log("** SHOW Images toggle")
        console.log(showImages)
        console.log(e.target)

    }
    
    function groupClicked(e) {
        setCatSelected("")
        setSearchMode("browse")
        setIsMinimized2(true)
        // setIsMinimized(false)
        scroll()
        let group = e.target.id
        setGroupSelected(group)

        if (contentRef2.current) {
                    setMaxHeight(`${contentRef2.current.scrollHeight}px`);
                }

        setStart(1)
        loadCategory(1, group)
    }

    function categoryClicked(e) {
        setSearchMode("browse")
        setIsMinimized2(true)
        let cat = e.target.id
        setCatSelected(cat)
        setStart(1)
        loadCategory(1, cat)
    }

    // for classic search 


    function classicSearch() {
        const fetch_url = `${API_URL}/classic?title=${title}&abstract=${abstract}&start=${start}&per_page=${perPageSetting}`
        fetchData(fetch_url)
    }

    function latestSearch(group) {
        fetchData(`${API_URL}/latest?group=${group}&start=${start}&per_page=${perPageSetting}`)
    }

    function handleClassicSearch() {
        scroll()
        setGroupSelected("")
        setCatSelected("")
        setStart(1)
        setSearchMode("classic")
        setIsMinimized(true)
        setIsMinimized2(true)
        classicSearch()
        
    }
    


    function handleSemanticSearch() {
        scroll()
        setStart(1)
        setSearchMode("semantic")
        setIsMinimized(true)
        setIsMinimized2(true)
        const fetch_url = `${API_URL}/supersearch?prompt=${semantic}&year_from=${values[0]}&year_to=${values[1]}&journal_ref=${isChecked}`
        fetchData(fetch_url)
    }


    function setBG() {
      const contentRow = document.getElementById('contentRow');
      contentRow.classList.add('content-background-reg');
    }
    useEffect(() => {
        setBG()
    }, []);



    let location = useLocation();
    console.log("****** LOCATION STATE")
    console.log(location)

    useEffect(() => {
        if(location.state) {
            console.log("**** MODE: ABSTRACT *****")
            setSearchMode("similar")
            setIsMinimized2(true)
            setIsMinimized(true)
            findSimilarAbstract(location.state['abstract'])
        } else {
            console.log("**** MODE: DEFAULT *****")
            

            switch (searchMode) {
            case 'browse':
                let group = ""
                if (catSelected == "") {
                    group = groupSelected
                } else {
                    group = catSelected
                }
                latestSearch(group)
            break;
            case 'classic':
                classicSearch()
            break;
            case 'semantic':
            displayText = 'Semantic Search';
            break;
            case 'similar':
            displayText = 'Similarity Search';
            break;
            default:
            displayText = 'Unknown mode.';
        }
        }
    }, [groupSelected, catSelected, start]);

    
    function showLargeImages(images) {
        setOverlayImages(images)
        setShowOverlay(true)
    }




    let displayText;

    switch (searchMode) {
        case 'browse':
        displayText = 'Browsing';
        break;
        case 'classic':
        displayText = 'Classic Search:';
        break;
        case 'semantic':
        displayText = 'Semantic Search';
        break;
        case 'similar':
        displayText = 'Similarity Search';
        break;
        default:
        displayText = 'Unknown mode.';
    }

    return (
        
    <>

        {/* <div>
            maxHeight: {maxHeight}
            maxHeight2: {maxHeight2}
            searchPadding: {searchPadding}
        </div> */}

        {/* BLACK INFO  */}
         {location.pathname != "/search" && (

            <div style={{
                backgroundColor: '#dcdae3'
            }}><br/>
                Please note: This project is created and maintained by a single individual. <a href="/project"> Learn more here. </a><br/>
                I would love to hear from you! Leave feedback, let me know which features you'd like to see implemented, and please report any bugs you encounter.
                <br/><br/>
            </div>
         )}
        {/* BLACK INFO  */}
        <div style={{
          backgroundColor: '#070a18'
        }}>
          {/* <Intro4 /> */}

        {location.pathname != "/search" && 
            (<Container style={{paddingBottom: '20pt', paddingTop: '20pt'}}>
            <Row>

                <Col md={4}>
                    <div style={{paddingBottom: '20pt', paddingTop: '20pt'}}>

                     <SemanticSearchForm isChecked={isChecked} setIsChecked={setIsChecked} values={values} setValues={setValues} handleSemanticSearch={handleSemanticSearch} semantic={semantic} setSemantic={setSemantic}/>
                       </div>
                </Col>
                <Col md={4}>

                <div class="parent-div">
                    <div class="child-div">
                        <div class="left-half">
                            <AiOutlineFileSearch fontSize={40} style={{position: 'absolute', right: '5pt'}}/>
                        </div>
                        <div class="right-half">
                            <h4 className='intro3-headers'>Semantic Search</h4>
                            <p>and <span className='text-highlight'>'Find Similar'</span> features can uncover rare papers that traditional search methods might miss!
                            </p>
                        </div>
                    </div>

                    <div class="child-div">
                        <div class="left-half">
                            <img src={chatGpt} width='120' style={{position: 'absolute', left: '21pt'}}/>
                        </div>
                        <div class="right-half">
                            <h4 className='intro3-headers'><span style={{color: 'black'}}>.</span></h4>
                            <p><span className='text-highlight-2'>Available now:</span> GPT Summaries of Papers.<br />
                                Coming soon: Discuss any paper with ChatGPT and more!
                            </p>
                        </div>
                    </div>
                </div>

                </Col>

                <Col md={4}>
                   <div class="parent-div">
                    <div class="child-div">
                        <div class="left-half">
                            <BsBookmarkStarFill fontSize={34} style={{position: 'absolute', right: '10pt'}}/>
                        </div>
                        <div class="right-half">
                            <h4 className='intro3-headers'><Pill color="#3f51b5" text="COMING SOON:"/>My Library</h4>
                            <p>You will be able to save and organize not only the papers you find here, but also any paper available online!</p>
                        </div>
                    </div>

                    <div class="child-div">
                        <div class="left-half">
                            <RiImageEditLine fontSize={42} style={{position: 'absolute', right: '8pt'}}/>
                        </div>
                        <div class="right-half">
                             <h4 className='intro3-headers'><Pill color="#3f51b5" text="COMING SOON:"/>Preview Images</h4>
                            <p>Preview images and charts from any paper directly within the search results!</p>
                        </div>
                    </div>
                </div>



                </Col>
               
            </Row>
        </Container>)
            }

        


        </div>

 <div style={{
          backgroundColor: '#fff'
        }}>
            
        {showOverlay && <ImageOverlay setShowOverlay={setShowOverlay} images={overlayImages}/>}

            {/* CATEGORIES */}
            <div id="scrollTo" style={{height: '0pt', visibility: 'hidden'}}>-- </div>
            <div ref={contentRef} className="toggle-content" style={{ maxHeight: maxHeight, paddingBottom: '0pt'}} >
                <Container>
                    <Row>
                        <Col>
                        <div  style = {{ paddingBottom: '5pt', paddingTop: '8pt', }} >
                            {Object.entries(cats_latest).map(
                                ([group, cat_list], x) => (
                                    <Button className={groupSelected==group && "styled-button-selected" || "styled-button"} id={group} key={x} onClick={groupClicked}>{group.toUpperCase()}</Button>
                                    )
                                )}
                        </div>
                        <div>
                            {groupSelected != "" && Object.entries(cats_latest[groupSelected]).map(
                                ([tag, cat_name], x) => (
                                    <PillButton className={catSelected==tag && 'pill-button-light-selected' || 'pill-button-light'} text={cat_name} id={tag} key={x} onClick={categoryClicked} />    
                                )
                            )}
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* SEARCH  */}
            <div ref={contentRef2} className="toggle-content-2" style={{ maxHeight: maxHeight2, paddingTop: '6pt'}} >
                <Container>
                    <Row>
                        <Col>
                                <SemanticSearchForm  isChecked={isChecked} setIsChecked={setIsChecked} values={values} setValues={setValues} handleSemanticSearch={handleSemanticSearch} semantic={semantic} setSemantic={setSemantic}/>
                        </Col>
                        <Col>
                                <ClassicSearchForm title={title} handleClassicSearch={handleClassicSearch} setTitle={setTitle} abstract={abstract} setAbstract={setAbstract}/>
                                
                        </Col>
                        {/* <Col>
                        searchMode:  {searchMode} <br />
                        searchPadding: {searchPadding} <br/>
                        maxHeight2: {maxHeight2} <br />
                        
                        <CoupleSlider />
                        </Col> */}

                        
                        
                    </Row>

                    </Container>
                    
            </div>

        {/* </div> */}

            <Container>
              
            <Row>
                <Col md={8} 
                    className="d-flex align-items-center" style={{textAlign: 'left'}}>
                        <Button style={{
                            marginRight: '5pt',
                            marginBottom: '5pt'
                        }}
                        type="submit" className="toggle-button styled-button-pink" onClick={toggleMinimize2} variant="light">{isMinimized2 ? 'Search Papers' : 'Hide Search' }</Button>
                        <Button style={{
                            marginRight: '5pt',
                            marginBottom: '5pt'
                        }} type="submit" className="toggle-button styled-button-pink" onClick={toggleMinimize} variant="light">{isMinimized ? 'Browse Categories' : 'Hide Categories' }</Button>
                
                
{/* 
                        <Toggle
                            id='show_images'
                            defaultChecked={showImages}
                            onChange={handleShowImagesToggle}
                            // style={{
                            //     paddingLeft: '5pt'
                            // }}
                            />
                            <label htmlFor='cheese-status' style={{paddingLeft: '8pt'}}> <span className='text-gray'>Show Images/Charts (Things load faster when OFF) </span></label> */}
                            
                </Col>
                <Col md={4}>
                        
                <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                    <div style={{paddingTop: '16pt'}}>
                        <Pagination scroll={scroll} setIsMinimized={setIsMinimized} setIsMinimized2={setIsMinimized2} paginationNavigate={paginationNavigate} loadCategory={loadCategory} start={start} setStart={setStart} totalCount={totalCount} perPage={perPageSetting}/>
                    </div>
                
                </div>
                </Col>
            </Row>

            <Row>
                <Col>
                {/* <Col className="d-flex align-items-end"> */}

                    {/* BREADCRUM INFO */}
                    <div style={{paddingLeft: '0pt', height: '24pt', textAlign: 'center'}}>
                        <Pill color='#0c1c30' text={displayText} />
                        <span className='text-gray'>
                        {searchMode == "browse" &&  (
                            <>
                                {groupSelected.toUpperCase()}: {catSelected == "" ? "All Categories " : cats_latest[groupSelected][catSelected]}
                                  |  Results: {totalCount} 
                            </>
                            )}
                         { searchMode == "classic" &&  (<>
                            {title && (<> Title: {title} </>)}  
                            {abstract && (<> Abstract: {abstract} </>)} 
                              |  Results: {totalCount} 
                         </>)}

                         {searchMode == "semantic" &&  (
                            <>
                                "{semantic}"
                                  |  Results: 20 (limited during Beta) 
                            </>
                            )}

                        {searchMode == "similar" &&  (
                            <>
                            Results: 20 (limited during Beta) 
                            </>
                            )}

                        </span>
                        
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                {/* <DataSpinner /> */}
                {isLoading ?  (<DataSpinner />) 
                      : (
                        articles.length == 0 ? <> <br /><br />No Results Found </> :
                        <Articles 
                            showImages={showImages}
                            showLargeImages={showLargeImages}
                            articles={articles}
                            fetchData={fetchData}
                            handleFindSimilar={handleFindSimilarAbstract}
                            searchMode={searchMode}
                        />
                        )
                      }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Pagination scroll={scroll} setIsMinimized={setIsMinimized} setIsMinimized2={setIsMinimized2} paginationNavigate={paginationNavigate} loadCategory={loadCategory} start={start} setStart={setStart} totalCount={totalCount} perPage={perPageSetting}/>
                </Col>
            </Row>
        </Container>
</div>

</>
    )
}

export default Latest