import React from 'react';
import MathComponent from './MathComponent';

function ParagraphWithMath({ paragraph }) {
    const tokens = paragraph.split(/(\$[^\$]+\$)/g);
    return (
        <>
            {tokens.map((token, index) => {
                if (token.startsWith('$') && token.endsWith('$')) {
                    const mathExpression = token.slice(1, -1);
                    return <MathComponent key={index} mathExpression={mathExpression} />;
                } else {
                    return <span key={index} dangerouslySetInnerHTML={{ __html: token }} />;
                }
            })}
        </>
    );
}
export default ParagraphWithMath;
