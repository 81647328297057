import React, { useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageCarousel.css';
import { BsArrowRightSquareFill, BsArrowLeftSquareFill } from "react-icons/bs";
import { HiMagnifyingGlassCircle} from "react-icons/hi2";
// import ImageOverlay from './ImageOverlay';
import Magnifying from '../elements/Magnifying';

function DummyDiv({showLargeImages, images}) {
    
  const [showOverlay, setShowOverlay] = useState(false);

  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    swipe: true,
    dots: true,
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  return (

    <>

    <div className="carousel-container" 
        style={{ 
            transition: 'height 0.5s ease-out', 
            height: '0pt',
            padding: '0',
            visibility: 'hidden'
            }}>

      <Slider ref={sliderRef} {...settings}>
          {/* {images.map(imageUrl => ( */}

          <div key="x">
            {/* <img src="https://ucarecdn.com/b8b8b9e4-6bba-4a3f-b3ac-efffb4cbf0b5/-/preview/260x300/" alt="carousel image" style={{ maxWidth: '100%', maxHeight: '100%' }} /> */}
          </div>
          <div key="xx">
            {/* <img src="https://ucarecdn.com/b8b8b9e4-6bba-4a3f-b3ac-efffb4cbf0b5/-/preview/260x300/" alt="carousel image" style={{ maxWidth: '100%', maxHeight: '100%' }} /> */}
          </div>
         {/* ))} */}
      </Slider>

      <div className="mid-button" 
        onClick={() => showLargeImages(images)}
        >
        <div className='magnifying-icon'>

          <Magnifying />
</div>
       
      </div>

      <button className="prev-button" onClick={handlePrevClick}>
        <BsArrowLeftSquareFill
            style={{
                position: 'absolute',
                top: '-2px',
                left: '0px',
                fontSize: '18pt',
            }}
          />
      </button> 
      
       <button className="next-button" onClick={handleNextClick}>
        <BsArrowRightSquareFill
            style={{
                position: 'absolute',
                top: '-2px',
                right: '0px',
                fontSize: '18pt',
            }}
          />
      </button>
    </div>
    </>
  );
}

export default DummyDiv;