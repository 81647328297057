import React from 'react';
import './styles/StyledButton.css';
import { Button } from 'react-bootstrap';

const StyledButton = ({ text, url, selectGroup}) => {
  return (
    <>
    <Button className="styled-button" href={url} onClick={selectGroup}>{text}</Button>
    {/* <Button className="styled-button" href="https://dashboard.mailerlite.com/forms/429223/87391058786256118/share" target="_blank">Feature Realease Alerts</Button> */}
    </>
  );
};

export default StyledButton;


// import React from 'react';
// import { Link } from 'react-router-dom'; // Import Link
// import './styles/StyledButton.css';

// const StyledButton = ({ text }) => {
//   return (
//     <Link to="/search" className="styled-button">
//       {text}
//     </Link>
//   );
// };

// export default StyledButton;

