import './styles/Dots.css'

const LoadingDots = () => {
    return (
        <>
        <span
            style={{
                fontSize: '7pt',
                color: '#00e2ff',
                fontWeight: '600',

            }}
        >In Progress</span>
              <div class="dots_position">
                <div class="loading__dots">
                    <div class="loading__dots__dot"></div>
                    <div class="loading__dots__dot"></div>
                    <div class="loading__dots__dot"></div>
                </div>
              </div>
        </>
    )
}
export default LoadingDots


