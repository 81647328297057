import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Button, Dropdown } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import { ImLink } from "react-icons/im";
import Tippy from '@tippyjs/react';
import { GrTextAlignLeft } from "react-icons/gr";
import { FaStar } from "react-icons/fa";

import DateBadge from '../elements/dateBadge';
import { API_URL } from '../common/Api';
import './styles/ArticleCardStyles.css'; // Importing the styles from the new CSS file
import Placeholder from 'react-bootstrap/Placeholder';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ImageCarousel from './ImageCarousel';
import ParagraphWithMath from '../elements/ParagraphWithMath';
import DummyDiv from './DummyDiv'
import ImageSelector from './ImageSelector';
import Pill from '../elements/Pill';


  // TODO: IMPORTANT: this is a duplicate from Latest.js
  const MAX_RETRIES = 3;
  async function getDataWithRetry(url, retryCount = 0) {
      try {
          const res = await fetch(url)
          if (!res.ok) { // res.ok returns false if the response status is an error (i.e., not in the range 200-299)
              if (res.status === 500) { // check if status is 500
                  if (retryCount >= MAX_RETRIES) { // if retryCount is greater than or equal to MAX_RETRIES, throw error
                      throw new Error(`HTTP error! status: ${res.status}`);
                  }
                  // wait for 2^retryCount * 100 milliseconds (exponential backoff)
                  await new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount) * 100));
                  return getDataWithRetry(url, retryCount + 1); // increment retryCount and make the request again
              }}
          return res
      } catch (error) {
          console.log(`> ERROR: ${error}`)
      }
  }


function MyVerticallyCenteredModal(props) {

    

    console.log("images -- : ")
    console.log(props.images)

    

    return (

        <Modal {...props} 
            dialogClassName="modal-100w"
            size="lg" 
            aria-labelledby="contained-modal-title-vcenter" 
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5>
                        <ParagraphWithMath paragraph={props.heading} />
                    </h5>
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {props.badImages && console.log(props.badImages)}
            {props.badImages && <ImageSelector images={props.badImages} onFinish={props.handleFinish} />}

            {props.title && <h6><ParagraphWithMath paragraph={props.title} /></h6>}
                {props.info && (<i><ParagraphWithMath paragraph={props.info} /><br /><br /></i>)}
                { props.loading && (<Placeholder as="p" animation="glow">
                  <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                  <Placeholder xs={6} /> <Placeholder xs={8} />
                </Placeholder>)}

                { !props.loading && !props.badImages && (<p><ParagraphWithMath paragraph={props.text} /></p>) } 

            </Modal.Body>

            {!props.badImages && (
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>)}
            
        </Modal>
    );
}

// use ONLY for testing!!! 
// const delay = (milliseconds) => {
//     const start = new Date().getTime();
//     let now = start;
//     while (now - start < milliseconds) {
//         now = new Date().getTime();
//     }
// }


const ArticleCard = (props) => {
    const [cardModal, setCardModal] = useState(false);
    
    const [summaryModal, setSummaryModal] = useState(false);
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [summaryText, setSummaryText] = useState("Something went wrong. If you see this, please, report this bug! (use button in top right corner)");
    const [summaryTitle, setSummaryTitle] = useState("");
    const [summaryInfo, setSummaryInfo] = useState("");
    const [summaryHeading, setSummaryHeading] = useState("");
    
    const [showLibrary, setShowLibrary] = useState(false);
    const handleCloseLibrary = () => setShowLibrary(false);
    const handleShowLibrary = () => setShowLibrary(true);
    
    const [images, setImages] = useState([]);
    const [badImages, setBadImages] = useState([]);
    const [imagesModal, setImagesModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isHovered, setIsHovered] = useState(false);

    function summarize(agent, pdf_url, title) {
        fetchSummary(agent, pdf_url)

        setSummaryHeading(title)
        console.log(`** agent: ${agent}`)
        
        if (agent == "gpt") {

            console.log(`** setting agent gpt`)
            setSummaryTitle("GPT Summary: ")
            setSummaryInfo("[Model being used: gpt-3.5-turbo-16k] This could take a while (on average ~30 seconds). Please be patient!")
        }
        if (agent == "claude") {  
                console.log(`** setting agent claude`)
                setSummaryTitle("Claude Summary: ")
                setSummaryInfo("[Model being used: Claude-2] This could take a while (on average ~30 seconds) Please be patient!")
        }

        setSummaryModal(true)
    }

    async function fetchSummary(agent, pdf_url, title) {
        setSummaryLoading(true)
        
        let fetch_url = `${API_URL}/summary?url=${pdf_url}&task=summary&agent=${agent}`
    
        try {
            const res = await getDataWithRetry(fetch_url)
            const result = await res.json()
            const summary = result['summary']
            // delay(5000);  // This will block the JS thread for 5 seconds
            setSummaryText(summary)
        } catch (error) {
            console.log(`ERROR: ${error}`) // TODO: consolidate errors
        }
        setSummaryInfo("")
        setSummaryLoading(false)
    }

    async function fetchImages(pdf_url, xid) {
            try {
                setIsLoading(true) // turn on SPINNER

                const fetch_url = `${API_URL}/charts?pdf_url=${pdf_url}&xid=${xid}`
                const res = await getDataWithRetry(fetch_url)
                const result = await res.json()

                const res_images = await result['images']
                // const status = await result['status']

                setImages(Object.values(res_images))

            } catch (error) {
                console.log(`ERROR: ${error}`) // TODO: consolidate errors
            }
            setIsLoading(false)
        }

    function showMarkBad() {
        setBadImages(images)
        setImagesModal(true)
    }


    function extractUUID(url) {
        // Regular expression to extract the UUID from the URL
        const regex = /\/([a-f0-9-]+)\/$/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        }
        return null;
    }

    function createApiUrlWithUUIDs(urls, apiUrl) {
        const uuids = urls.map(url => extractUUID(url)).filter(uuid => uuid !== null);
        if (uuids.length > 0) {
            const uuidsQueryParam = uuids.join(',');
            const apiUrlWithUUIDs = `${apiUrl}/mark_bad?uuids=${uuidsQueryParam}`;
            return apiUrlWithUUIDs;
        } else {
            return null; // No valid UUIDs found
        }
    }

    async function handleFinish(selectedImages) {
        const fetch_url = createApiUrlWithUUIDs(selectedImages, API_URL)
        console.log("Selected images:", selectedImages)
        console.log("fetch_url:" , fetch_url)
        setImagesModal(false)
        const res = await fetch(fetch_url)
        fetchImages(props.article['pdf'], props.article['id'])
        // fetch("")
        // Process the selected image URLs with your logic here
    };

    // useEffect(() => { 
    //     props.showImages && isLoading && fetchImages(props.article['pdf'], props.article['id'])
    // }, [props.showImages])

    return (

        <>

       
        <Card bg="Light" className="card-hover">

            {/* MODAL: Abstract */}
            <MyVerticallyCenteredModal
                show={cardModal}
                onHide={() => setCardModal(false)}
                text={props.article['abstract']}
                heading={props.article['title']}
                title="Abstract"
            />

            {/* MODAL: Summary */}
            <MyVerticallyCenteredModal
                show={summaryModal}
                onHide={() => setSummaryModal(false)}
                text={summaryText}
                heading={summaryHeading}
                title={summaryTitle}
                loading={summaryLoading}
                info={summaryInfo}
            />

            {/* MODAL: Bad Images */}
            <MyVerticallyCenteredModal
                show={imagesModal}
                onHide={() => setImagesModal(false)}
                badImages={badImages}
                text="text"
                heading="Report issue with Images"
                // title="GPT Summary: "
                // loading={summaryLoading}
                // info="[Model being used: gpt-3.5-turbo-16k] This could take a while. Please be patient!"
                handleFinish={handleFinish}
            />

        
            <Card.Header as="p" className="card-header">{props.article['date']}</Card.Header>
            <Card.Body>

                <DateBadge dateString={props.article['date']} searchMode={props.searchMode} />
                {props.article['match'] && <Badge className="badge-search">{props.article['match']}%</Badge>}
                {/* {props.searchMode === 'semantic' && <Badge className="badge-search">{props.article['match']}%</Badge>} */}

                <DummyDiv showLargeImages={props.showLargeImages} images={images} />

                {props.showImages && isLoading ?  (<> loading images </>) 
                      : (
                        props.showImages && images.length > 0 && (
                            <>
                                <div style = {{
                                    textAlign: 'right',
                                    fontSize: 'smaller', 
                                    top: '-10pt',
                                    position: 'relative',
                                }}>
                                    <span className='muted link-simulator' onClick={showMarkBad}>Mark bad images</span>
                                </div>
                                <ImageCarousel showLargeImages={props.showLargeImages} images={images} />
                            </>
                            )
                        )
                      }
                
                {/* {  !props.showImages && <DummyDiv showLargeImages={props.showLargeImages} images={images} />} */}


                <Card.Subtitle className="article-subtitle">
                    <ParagraphWithMath paragraph={props.article['title']} />
                </Card.Subtitle>

                <Card.Text  style={{margin: "0"}} className="article-authors">
                    {props.article['authors']}
                    </Card.Text>


                {/* --- JOURNAL REF --- */}
                <div style={{textAlign: 'left'}}>
                    { props.article['journal_ref'] ? <Pill color="#b0b0b0" text={props.article['journal_ref']} fontSize='7pt'/> : ""}
                    {/* { props.article['journal_ref'] == null ?  "" : <Pill color="#607d8b" text={props.article['journal_ref']} fontSize='7pt'/> } */}
                    {/* doi: {props.article['doi']} */}
                    {/* { props.article['doi'] ? <Pill color="#8bc34a" href="" text="doi" fontSize='7pt'/> : "" } */}
                </div>


                {/* --- BUTTONS --- */}

                {/* PDF */}
                <a href={props.article['pdf']} target="_blank" rel="noreferrer">
                    <Button variant="light" className="pdf-button">PDF</Button>
                </a>

                {/* Abstract */}
                {/* <Tippy content="View Abstract" delay="20" theme='tomato'> */}
                    <Button variant="light" className="abstract-button" onClick={() => setCardModal(true)}>
                        {/* <GrTextAlignLeft /> */}
                        Abstract
                    </Button>
                {/* </Tippy> */}

                

                {/* URL */}
                <Button variant="light" className="link-button" href={props.article['url']} target="_blank" rel="noreferrer"
                    style={{
                        marginRight: '3pt'
                    }}
                >
                    <ImLink style={{
                        fontSize: 'larger'
                    }}/>
                </Button>


                {/* <Button variant="light" disabled={false} className="library-button" onClick={handleShowLibrary}>
                        
                </Button> */}
                {/* <div style={{
                    padding: '5pt'
                }}> */}
                <a href="#" >
                    <FaStar style={{
                        marginLeft: '3pt',
                        fill: isHovered ? '#ffc107' : '#555555'
                    }} 
                    onClick={handleShowLibrary}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    />
                </a>
                    
                {/* </div> */}
                
                <Offcanvas show={showLibrary} onHide={handleCloseLibrary}>
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title>My Library</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                    Coming soon! <br /><br />
                    You will be able to save papers and organize them using categories and tags. <br /><br />
                    Moreover, <span className='text-highlight'>you will be able to save not only ArXiv papers but any paper from any journal! </span> <br />
                    This will make it easy to keep all your research in one place.<br /><br />
                    
                    Come back soon or sign up for <a href='https://dashboard.mailerlite.com/forms/429223/87391058786256118/share'>Feature Release Alerts</a> 

                    </Offcanvas.Body>
                </Offcanvas>

                <br />
                {/* GPT */}
                <Dropdown style={{ display: 'inline-block' }}  drop="up">
                  <Dropdown.Toggle variant="info" id="dropdown-basic" 
                    // style={{color: 'white', fontWeight: '700', padding: '4pt', fontSize: '9pt', paddingLeft: '6pt', paddingRight: '6pt'}}
                    style = {{color: 'white'}}
                    className="similar-button"
                    >
                  GPT
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>- GPT Options -</span></Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => summarize("gpt", props.article['pdf'], props.article['title'])} >Summarize Paper</Dropdown.Item>
                  {/* <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>Summarize [Coming Soon]</span></Dropdown.Item> */}
                  <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>Discuss [Coming Soon]</span></Dropdown.Item>
                  <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>Learn [Coming Soon]</span></Dropdown.Item>
                  </Dropdown.Menu>
                 </Dropdown>

                {/* GPT */}
                <Dropdown style={{ display: 'inline-block' }}  drop="up">
                  <Dropdown.Toggle variant="info" id="dropdown-basic" 
                    // style={{color: 'white', fontWeight: '700', padding: '4pt', fontSize: '9pt', paddingLeft: '6pt', paddingRight: '6pt'}}
                    className="similar-button"
                    style = {{color: 'white', backgroundColor: '#cc785c', borderColor: '#cc785c'}}
                    >
                  Claude
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>- Claude Options -</span></Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => summarize("claude", props.article['pdf'], props.article['title'])} >Summarize Paper</Dropdown.Item>
                  {/* <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>Summarize [Coming Soon]</span></Dropdown.Item> */}
                  <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>Discuss [Coming Soon]</span></Dropdown.Item>
                  <Dropdown.Item disabled href="#"><span style={{fontSize: '10pt'}}>Learn [Coming Soon]</span></Dropdown.Item>
                  </Dropdown.Menu>
                 </Dropdown>

                {/* Find Similar */}
                <a href="#" onClick={props.handleFindSimilar}>
                    <Button variant="dark" id={props.article['xid']} className="similar-button">Find Similar</Button>
                </a>
            </Card.Body>
        </Card>
    </>
    );
}

export default ArticleCard;
