import { Container, Row, Col } from 'react-bootstrap'
import ArticleCard from './ArticleCard'

const Articles = ({showImages, showLargeImages, fetchData, handleFindSimilar, articles, searchMode}) => {
    return (
        <Container className="mt-4">     
            <Row xs={1} md={3} lg={4}>
            {articles.map((article, i) => (
                <Col key={i} className="pb-4">
                    <ArticleCard 
                        showImages={showImages}
                        showLargeImages={showLargeImages}
                        article={article} 
                        key={i} 
                        handleFindSimilar={handleFindSimilar} 
                        fetchData={fetchData}
                        searchMode={searchMode}
                        />
                </Col>
                ))
            }
            </Row>

            {/* {showOverlay && (
                <div style={styles.overlay}>
                <img src={imageUrl} alt="Overlay" style={styles.image} />
                <button style={styles.closeButton} onClick={() => setShowOverlay(false)}>
                    Close
                </button>
                </div>
            )} */}

            <Row>
                {
                    (searchMode=='semantic' || searchMode=='similar') && articles.length > 0 && (
                    <span
                    style={{
                        color: '#607d8b',
                        fontSize: '11pt',
                        textAlign: 'center',
                        forntWeight: '500',
                        paddingTop: '20pt',
                        paddingBottom: '20pt',
                    }}>
                    Currently Semantic Search results are limited to 20 items. This will change. Sign up for <a href='https://dashboard.mailerlite.com/forms/429223/87391058786256118/share'>Feature Release Alerts</a></span>
                    )
                }  
            </Row>
        </Container>
    )
}
export default Articles