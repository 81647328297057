import Latest from "../components/Latest";
import "../styles/QuadrantLayout.css";
import "../styles/app.css";

import Intro4 from "../components/Intro4";
import Intro3 from "../components/Intro3";
import Intro2 from "../components/Intro2";
import Intro5 from "../components/Intro5";
import Intro from "../components/Intro";
import { Container, Row, Col } from "react-bootstrap";
import Pill from '../elements/Pill';

function IntroLayout({}) {

  return (
    <>
    

      <div
        style = {{
          // backgroundColor: '#fff',
          // background-color: rgb(255, 255, 255);
          position: 'absolute',
          width: '100%',
          left: '0',
          height: '400pt',
        }}
        >
          
          <Intro5 />

       
          <Latest />


      </div>

    </>
  );
}

export default IntroLayout;





{/* 
<div className="intro-container-2">
        <div className="intro-container-3"> */}


        {/* <Container>
          <Row>
            <Col md={12}>
              <div>

              <p><span style={{fontSize: '28pt', color: '#fff' }} >
              GPT-powered Search Engine<br /> for Scientific Papers
              </span></p>
              <span style = {{color: '#b3c1cf', fontSize: 'larger'}} >
                Supersymmetry is a better UI interface for browsing <span className="lime"> ArXiv papers</span>  enhanced with <span className="lime">GPT tools.</span><br />It uses <span className="lime">Semantic Search </span>  
                as well as a <span className="lime">Similarity Search</span> which lets you <b>find papers classic engines would miss! </b>
              </span>
            </div>
            </Col>
          </Row>
        </Container>


      </div>
</div> */}

          
        
        //   <div className="intro-container-2">


        // <Container>
        //   <Row>
        //     <Col md={12}>
        //       <div className="intro-container-3">

        //       <p><span style={{fontSize: '46pt', color: '#fff', fontFamily: 'fangsong', lineHeight: 'initial'}} >
        //       AI-powered Search Engine<br /> for Scientific Papers
        //       </span>

              
        //       <span  style={{color: '#fff', fontSize: '14pt'}}>
        //         <br /><i>
        //           Supersymmetry is a semantic search engine for <span className="lime"> ArXiv papers</span>  enhanced with <span className="lime">AI tools.</span>
        //           </i>
        //       </span>

        //       </p>

        //       <br />

        //       <p><span className="into-text-small-headers" >
        //       {/* <p><span style={{fontSize: '22pt', color: '#0dcaf0', fontWeight: '600'}} > */}
        //       Semantic Search
        //       </span>
        //       <span  className="intro-text">
        //         <br />
        //         Semantic Search is <span className="lime">based on the meaning of a paper</span> which is encoded using GPT embeddings. <br />
        //         This produces better results and <span className="lime">could uncover rare papers </span>traditional search methods might miss.
        //       </span>
        //       </p>

             
        //       <p><span className="into-text-small-headers" >
        //       {/* <p><span style={{fontSize: '22pt', color: '#0dcaf0', fontWeight: '600'}} > */}
        //         Similarity Search
        //         </span>
        //         <span className="intro-text">
        //           <br />
        //           Use the 'Find Similar' feature on any paper to <span className="lime">discover all papers most similar</span> to it!
        //         </span>
        //       </p>

        //       <p><span className="into-text-small-headers" >
        //       <span style={{color:"#25cff2"}}>
        //       GPT 
        //       </span>
        //       <span style={{color:"#FFF"}}>
        //        +  
        //       </span>
              
        //       <span style={{color:"#cc785c"}}>
        //         Claude
        //         </span> <Pill color="#E91E63" text="NEW"/>
        //       </span>
        //       <span className="intro-text">
        //         <br />Generate <span className="lime">paper summaries</span> using either GPT or Claude (Just added: Sep 6th, 2023)
        //       </span>
        //       </p>

        //     </div>
        //     </Col>
        //   </Row>
        // </Container>



        //   </div> 




            {/* <div className="intro-container-3">

            
              <div>

                <p><span style={{fontSize: '28pt', color: '#fff' }} >
                GPT-powered Search Engine<br /> for Scientific Papers
                </span></p>
                <span style = {{color: '#b3c1cf', fontSize: 'larger'}} >
                  Supersymmetry is a better UI interface for browsing <span className="lime"> ArXiv papers</span>  enhanced with <span className="lime">GPT tools.</span><br />It uses <span className="lime">Semantic Search </span>  
                  as well as a <span className="lime">Similarity Search</span> which lets you <b>find papers classic engines would miss! </b>
                </span>
              </div>
            
            </div> */}




            