import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import './styles/StyledButton.css';


import '../App.css';

const ClassicSearchForm = ({handleClassicSearch, setTitle, title, abstract, setAbstract}) => {
    return (


        <Card>
        <Card.Header as="h5">Classic Search</Card.Header>
        <Card.Body>
            {/* <Card.Title></Card.Title> */}
            <Card.Text>
            

                <Form >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control 
                        type = "text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        as="textarea" 
                        rows="1" 
                        placeholder="Title" 
                        style={{
                            outline: 'none',
                            marginBottom: '5pt',
                        }}
                        />
                    <Form.Control 
                        type = "text"
                        value={abstract}
                        onChange={(e) => setAbstract(e.target.value)}
                        as="textarea" 
                        rows="2" 
                        placeholder="Abstract" 
                        style={{
                            outline: 'none',
                        }}
                        />
                {/* <Form.Text className="text-muted" style={{textAlign: 'left'}}>
                    
                </Form.Text> */}
                </Form.Group>
                </Form>


            </Card.Text>
            <Button className="styled-button-light" type="submit" onClick={handleClassicSearch} >Search</Button>
        </Card.Body>
        </Card>
    )
}

export default ClassicSearchForm;