import { Container, Row, Col } from 'react-bootstrap'
import { PaginationControl } from 'react-bootstrap-pagination-control';
import '../styles/pagination.css';


const Pagination = ({scroll, paginationNavigate, setIsMinimized, setIsMinimized2, loadCategory, start, setStart, totalCount, perPage}) => {
    
    function changeStart(new_start) {
        // paginationNavigate()
        console.log("[PaginationComponent] >> changePage()")
        // window.scrollTo(0,0);
        setStart(new_start) 
        setIsMinimized(true)
        setIsMinimized2(true)
        scroll()
        // loadCategory(new_start, "")
        // fetchSourcePage(source, new_page)
    }

    return (



                <PaginationControl
                    page={start}
                between={4}
                total={totalCount}
                limit={perPage} 
                changePage={(page) => {
                        changeStart(page);
                    }}
                    ellipsis={1}
                    />

    )
}
export default Pagination




