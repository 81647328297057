import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

import CoupleSlider from '../elements/CoupleSlider';
import React, { useState, useEffect, useRef} from 'react';

import './styles/StyledButton.css';
import '../App.css';

const SemanticSearchForm = ({isChecked, setIsChecked, values, setValues, handleSemanticSearch, setSemantic, semantic}) => {
    
    
    

    const handleChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (


        <Card>
        <Card.Header as="h3">Semantic Search</Card.Header>
        <Card.Body>
            {/* <Card.Title></Card.Title> */}
            <Card.Text>
            

                <Form >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control 
                        class="form-control"
                        type = "text"
                        value={semantic}
                        onChange={(e) => setSemantic(e.target.value)}
                        as="textarea" 
                        rows="4" 
                        placeholder="Semantic search matches the meaning of the entered text, not the literal words. Tip: Paste an abstract of any paper here, or type a long-form description of the type of research you're looking for."
                        style={{
                            outline: 'none',
                            marginBottom: '5pt',
                        }}
                        />

               

                {/* <Form.Text className="text-muted" style={{textAlign: 'left'}}>
                    
                

                </Form.Text> */}
                </Form.Group>
                </Form>


            </Card.Text>
            
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{width: '70%'}}>
                    <CoupleSlider values={values} setValues={setValues} />
                    <label>
                        <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(event) => {setIsChecked(event.target.checked)}}
                        style={{margin: '2pt', marginTop: '10pt'}}
                        />
                        <span style={{fontSize: "9pt"}}>
                            Has Journal Reference
                        </span>
                    </label>
                </div>

                <div style={{width: '30%', textAlign: 'right'}}>
                    <Button className="styled-button-light" type="submit" onClick={handleSemanticSearch}>Search</Button>
                </div>
            </div>


        </Card.Body>
        </Card>
    )
}

export default SemanticSearchForm;