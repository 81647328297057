import { ThreeDots } from "react-loader-spinner";
import { MAIN_COLOR } from "../common/theme";

function DataSpinner() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
    <ThreeDots 
        height="80" 
        width="80" 
        radius="9"
        // color="#9ACD32"
        // color="#1cd4ff" // clear blue
        color="#929292" // light grey
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
        />
    </div>
  )
}

export default DataSpinner;