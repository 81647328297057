import React from 'react';
import './styles/StyledButton.css';
import { Button } from 'react-bootstrap';

const FeatureAlertsButton = () => {
  return (
    <Button className="styled-button" href="https://dashboard.mailerlite.com/forms/429223/87391058786256118/share" target="_blank">Feature Realease Alerts</Button>
  );
};

export default FeatureAlertsButton;
