import FeatureAlertsButton from "../components/FeatureAlertsButton";

const Explore = () => {

    return(
    <div className="centered-text-container">
      <div className="centered-text">
        <span
          style={{
            fontSize: '14pt',
            fontWeight: 'bold',
          }}>
          Research Trends <br/>[Coming Soon]</span>
        <br />
        <br />
        <span style = {{color: '#b3c1cf'}}>
          Becuase Supersymmetry semantically vectorizes all papers, it will be possible (and very interresing!) to create cluster detection and visualization to automatically track any emerging reserach areas that are gaining popularity.   <br />
          <br /><br />Come back soon or sign up for
        </span>
        <br />
             <FeatureAlertsButton />
             <br />
             <span className="muted"> I only send a very occasional email to announce new features. <br />No spam, I'm a human being too! :) </span>
            
      </div>
    </div>
   );
  };
export default Explore;