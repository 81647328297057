
// export const API_URL = "https://sophos-api-public.onrender.com"

let API_URL = "not_set"

if (process.env['NODE_ENV'] === "development") {
    API_URL = "http://127.0.0.1:5050"
} else if (process.env['REACT_APP_ENV'] === "staging") {
    API_URL = "https://ss-api-staging.onrender.com"
} else {
    API_URL = "https://sophos-api-public.onrender.com"
};

export { API_URL };
// export const API_URL = "http://api-internal:5050"
