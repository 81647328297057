import React, { useState } from 'react';

const ImageOverlay = ({ setShowOverlay, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClose = () => {
    setShowOverlay(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <div style={styles.overlay} onClick={handleClose}>
        <div style={styles.imageContainer}>
          <img 
            src={images[currentImageIndex]} 
            alt="Displayed Overlay" 
            style={styles.image} 
            onClick={stopPropagation} // Moved stopPropagation to the image itself
          />
          
          <div style={styles.iconContainer}>
            {images.map((imgUrl, index) => (
              <img 
                key={index}
                src={imgUrl} 
                alt="Thumbnail" 
                style={styles.icon}
                onClick={(e) => { 
                  setCurrentImageIndex(index); 
                  stopPropagation(e);  // Add stopPropagation to each thumbnail
                }}
              />
            ))}
          </div>
        </div>
        <button style={styles.closeButton} onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );

}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
    imageContainer: {
    position: 'relative', // This is essential for the absolute positioning of its child elements.
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '95%',  // This gives some space for the thumbnails at the bottom.
    justifyContent: 'center', // This will vertically center the main image
  },
  iconContainer: {
    position: 'absolute', // Pins the container to the bottom of its parent
    bottom: 0,  // This gives a little margin at the bottom. Adjust as necessary.
    left: '50%',
    transform: 'translateX(-50%)',  // This combination of left and transform will center the container
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
  },
  image: {
    maxWidth: '90%',
    maxHeight: '83%',
    objectFit: 'contain',
  },
  icon: {
    width: 50,
    height: 50,
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#fff',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    zIndex: 10,
  },
};


export default ImageOverlay;
