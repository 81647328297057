import React from 'react'
import '../App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col , Div} from 'react-bootstrap'

//  --- components
import NavbarMenu from '../components/NavbarMenu' 
import { Outlet } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FeedbackModal from '../components/FeedbackModal'


// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Modal heading
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <h4>Centered Modal</h4>
//         <p>
//           Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
//           dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
//           consectetur ac, vestibulum at eros.
//         </p>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }



const MainLayout = () => {

    const [modalShow, setModalShow] = React.useState(false);

    return (
    <div>
        <div
            style = {{
                position: 'fixed',
                right: '20pt',
                bottom: '10pt',
                zIndex: '1000'
            }} >

        
            <Button 
                
                className="styled-button-vivid"
                // variant="primary" 
                onClick={() => setModalShow(true)}>
                Leave Feedback
            </Button>
            <span style={{
                fontSize: '7pt',
                fontWeight: '700',
            }}>
                <br />
                I really want to hear your thoughts! :)
            </span>
        </div>
        <FeedbackModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            />



        <Container fluid >
            {/* HEADER */}
            <Row>
                <NavbarMenu />
            </Row>
            {/* CONTENT */}
            <Row className="content-container" id="contentRow">
                {/* <Col md={1} ></Col> */}
                    <Col style={{ 
                        // maxWidth: '1320px', 
                        margin: '0 auto' 
                        }}>
                        <Outlet />
                    </Col> 
                {/* <Col md={1} ></Col> */}
            </Row>
        </Container>
     </div>
    );
  };
export default MainLayout; 