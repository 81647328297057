import React from 'react';
import { HiMagnifyingGlassCircle } from 'react-icons/hi2';

const StyledIcon = () => {
  const iconStyle = {
    position: 'relative',
    fontSize: '36px', // You can adjust this
    color: 'black'
  };

  const circleStyle = {
    content: '',
    position: 'absolute',
    top: '60%',
    left: '50%',
    width: '50%', // Adjust to make the circle smaller or bigger relative to the icon
    height: '50%',
    backgroundColor: 'white', // The fill color of the circle
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '-1',  // This ensures the circle stays behind the icon
  };

  return (
    <div style={iconStyle}>
      <div style={circleStyle}></div>
      <HiMagnifyingGlassCircle />
    </div>
  );
}

export default StyledIcon;
