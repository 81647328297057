import { useState, useRef, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

function Explainer() {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const targetRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the clicked element is outside the target element
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only once


  return (
    <div ref={ref}>
      {/* <Button onClick={handleClick}>Holy guacamole!</Button> */}
          <button
          ref={targetRef}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: '#008ccc',
          textDecoration: 'underline',
          cursor: 'pointer',
          marginLeft: '7pt',
          marginTop: '14pt',
          fontWeight: '600'
        }}
        onClick={handleClick}
        // onMouseOver={handleClick}
      >
        Why are articles out of order?
        </button>  

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          {/* <Popover.Header as="h3">Popover bottom</Popover.Header> */}
          <Popover.Body>
            {/* <strong>Holy guacamole!</strong>  */}
            Currently Articles are shown in the order of most recently <strong>updated</strong> on ArXiv. 
            <br /><br />
            In the <strong>next feature release</strong> I will be adding a toggle to sort by <br /><strong>Published</strong> vs <strong>Updated</strong> date. 
            <br /><br />
            Come back soon, or sign up for <a href="https://dashboard.mailerlite.com/forms/429223/87391058786256118/share" target="_blank">Feature Relese Alerts.</a> 
            <br />No spam! Just release alerts :)<br />
            Also, check out all <a href="/features">planned features</a> coming soon.
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default Explainer;