
import { Col, Row, Container } from 'react-bootstrap';
import './styles/Intro4.css';
import chatGpt from '../elements/chatGpt.png'


import { AiOutlineFileSearch } from "react-icons/ai"
import { BsCalendarRange } from "react-icons/bs";
import { RiImageEditLine } from "react-icons/ri";


function Intro3() {
  
    const iconSizes = 80 
    return (

    <>

        <Container>
            <Row>
                <Col md={2}>
                    test
                </Col>
                <Col md={4}>

                <div class="parent-div">
                    <div class="child-div">
                        <div class="left-half">
                            <AiOutlineFileSearch fontSize={40} style={{position: 'absolute', right: '5pt'}}/>
                        </div>
                        <div class="right-half">
                            <h4 className='intro3-headers'>Semantic Search</h4>
                            <p>and <span className='text-highlight'>'Find Similar'</span> search could uncover rare papers classic search would miss!
                            </p>
                        </div>
                    </div>

                    <div class="child-div">
                        <div class="left-half">
                            <img src={chatGpt} width='120' style={{position: 'absolute', left: '21pt'}}/>
                        </div>
                        <div class="right-half">
                            <h4 className='intro3-headers'><span style={{color: 'black'}}>.</span></h4>
                            <p><span className='text-highlight-2'>Available now:</span> GPT Summaries of Papers.<br />
                                Coming soon: Discuss any paper with ChatGPT and more!
                            </p>
                        </div>
                    </div>
                </div>

                </Col>

                <Col md={4}>
                   <div class="parent-div">
                    <div class="child-div">
                        <div class="left-half">
                            <BsCalendarRange fontSize={34}  style={{position: 'absolute', left: '21pt'}}/>
                        </div>
                        <div class="right-half">
                            <h4 className='intro3-headers'>Date Ranges ** </h4>
                            <p>Using native ArXiv search you cannot select date ranges - with Semantic Search you can!  </p>
                        </div>
                    </div>

                    <div class="child-div">
                        <div class="left-half">
                            <RiImageEditLine fontSize={52}/>
                        </div>
                        <div class="right-half">
                            <h4 className='intro3-headers'><span style={{color: 'black'}}>.</span></h4>
                            <h4 className='intro3-headers'>Preview Images</h4>
                            <p>Preview images and charts of any paper right in the search results!</p>
                        </div>
                    </div>
                </div>

                </Col>
               
            </Row>
        </Container>

            
    </>

  );
}

export default Intro3;