// ImageSelector.js
import React, { useState } from 'react';

// Thumbnail component
const Thumbnail = ({ src, isSelected, toggleSelect }) => {
    const containerStyle = {
        position: 'relative',
        cursor: 'pointer',
        width: '100px',
        height: '80px',
        overflow: 'hidden',
    };

    const imageStyle = {
        width: '100%',  // Make the image width 100% of the container
        height: '100%', // Make the image height 100% of the container
        objectFit: 'stretch', // Stretch the image to fit the container
        filter: isSelected ? 'brightness(50%)' : 'none'
    };

    const textStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'red',
        fontWeight: 'bold',
        zIndex: 2
    };

    return (
        <div style={containerStyle} onClick={toggleSelect}>
            <img src={src} alt="thumbnail" style={imageStyle} />
            {isSelected && <div style={textStyle}>X</div>}
        </div>
    );
};




const ImageSelector = ({ images, onFinish }) => {
    const [selected, setSelected] = useState([]);

    const toggleImage = (img) => {
        setSelected((prevState) =>
            prevState.includes(img) 
            ? prevState.filter((i) => i !== img)
            : [...prevState, img]
        );
    };

    return (
        <div style={{
            padding: '6pt',
        }}>
            <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center', // Horizontal centering
                        padding: '6pt',
                        
                    }}>

            <span >
                Sometime script picks up on non-relevant visual elements or splits charts when processing PDFs. <br />
                <span style={{
                    color: 'red',
                    fontWeight: '600'
                }}> Please, mark images which you believe should be removed and click "Finish". 
                </span>  <br />
                Thank you for your help cleaning this up! :) <br />
            </span>
                    </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 100px)', gap: '6px', padding: '5pt', paddingTop: '24pt', paddingBottom: '24pt'}}>
                {images.map((img, index) => (
                    <Thumbnail
                        key={index}
                        src={img}
                        isSelected={selected.includes(img)}
                        toggleSelect={() => toggleImage(img)}
                    />
                ))}
            </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Horizontal centering
                        padding: '6pt',
                    }}>

                        <button onClick={() => onFinish(selected)}>Finish</button>
                    </div>
        </div>
    );
};

export default ImageSelector;
