import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

// import { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import React, { useState, useEffect, useRef} from 'react';
import { API_URL } from '../common/Api';

const FeedbackModal = (props) => {

  const [feedbackText, setFeedbackText] = useState("")
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('1');

  
  function postFeedback() {
    const post_url = `${API_URL}/feedback?type=${radioValue}&text=${feedbackText}`

    try {
        console.log(`posting feedback... ${feedbackText}`)
        const res = fetch(post_url)
      } catch (error) {
          console.log(`> ERROR: ${error}`) // TODO: consolidate errors 
      }
    props.onHide()
  }

  const radios = [
    { name: 'General Feedback', value: '1' },
    { name: 'Feature Request', value: '2' },
    { name: 'Bug!', value: '3' },
    { name: 'Contact Me', value: '4' },
  ];

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          What is it? 
          <ButtonGroup style={{
            marginLeft: '20pt'
          }}>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={'outline-dark'}
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <p>
        

      
        <Form >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control 
                        class="form-control"
                        type = "text"
                        value={feedbackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                        as="textarea" 
                        rows="6" 
                        placeholder="I really appreciate your feedback! Now, what's on your mind?.."
                        style={{
                            outline: 'none',
                            marginBottom: '5pt',
                            marginTop: '10pt'
                        }}
                        />
                </Form.Group>
                </Form>

            {/* <Button className="styled-button-light" type="submit" onClick={postFeedback} >Send Feedback</Button> */}

        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="styled-button-light" onClick={postFeedback}>Send Feedback</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default FeedbackModal;
// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <FeedbackModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);