import StyledButton from "../components/StyledButton";
import LoadingDots from "../elements/LoadingDots";

const Features = () => {

    return(
    <div className="centered-text-container">
      {/* <div className="centered-text"> */}
      <div
        style = {{
          textAlign: 'left',
          justifyContent: 'left',
        }}
      >
        
      <p>
        {/* <span 
          // class="text-supporting"
          style = {{
            color: '#607d8b',

          }}
          >  */}
          
       Here is a list of features I am working on, have already implemented, or plan to add soon. <br />
       If there are any features you would like me to develop, please feel free to contact me and let me know what you have in mind.<br />
        {/* </span> */}
      </p>
        <ul>
          <li class="features-done">Semantic Search <div class="checkmark"></div>  <span class="text-supporting"> [May 7, 2023] </span></li>
          <li class="features-done">Similarity Search [Find Similar] on Papers <div class="checkmark"></div> <span class="text-supporting"> [May 7, 2023] </span></li>
          <li class="features-done">Browse all Categories <div class="checkmark"></div>  <span class="text-supporting"> [Aug 8, 2023] </span></li>
          <li class="features-done">LIVE updates of all ArXiv papers! <div class="checkmark"></div>  <span class="text-supporting"> [Aug 8, 2023] </span></li>
          <li class="features-done">Classic "keyword" Search  <div class="checkmark"></div><span class="text-supporting"> [Aug 13, 2023] </span></li>
          <li class="features-done">GPT-4 Summaries of Papers  <div class="checkmark"></div><span class="text-supporting"> [Aug 13, 2023] </span></li>
          <li class="features-done">Limit Semantic Search by DATE(Year) + JOURNAL REF <div class="checkmark"></div><span class="text-supporting"> [Sep 1, 2023] </span></li>
          <li class="features-done">Claude (Anthropic) Paper Summaries! :) <div class="checkmark"></div><span class="text-supporting"> [Sep 6, 2023] </span></li>
          <li class="muted-list">PDF Images/Charts Previews  <LoadingDots /> </li>
          <li class="muted-list">More GPT + Claude magic!! <LoadingDots /></li>
          <li class="muted-list">More filters for Seamantic/Classic Searches<LoadingDots /></li>
          <li class="muted-list">User Accounts</li>
          <li class="muted-list">"My Library" (Save Papers and searches)</li>
          <li class="muted-list">Paper Match Alerts: Receive email alerts when a paper matching your search prompt gets published</li>
          <li class="muted-list">Tag/Categorize saved papers</li>
          <li class="muted-list">Discuss papers with ChatGPT</li>
          <li class="muted-list">GPT Model trained on lastest research/news (I will start with Physics)</li>
          <li class="muted-list">Reseaarch Trends & Hot Topics (Semantic Clustering)  </li>
        </ul>
      
      Once new features are released, I will send an email announcement.<br />
      Sign up here to receive those here: <br/> 
      <br />
      <StyledButton text="New Feature Alerts Signup" url="https://dashboard.mailerlite.com/forms/429223/87391058786256118/share" />
      <br /><br />
      I will plan to maintain approximately a weekly cadence for new features. <br />
      {/* Also, you can help me move thing faster by supporting this project. <br />
      If it gains enough support, it will make it possible for me to be working on it full time.
      I do hope you can see (as I do) the immense possibilities this project holds! :)  */}
      <br />

      </div>

    </div>
   );
  };

export default Features;