import React from 'react';
import Badge from 'react-bootstrap/Badge'

const DateBadge = ({searchMode, dateString}) => {


    // Convert the input dateString to a Date object
    const date = new Date(dateString);

    // console.log("*** DATE: ")
    // console.log(date)
    
    // Get today's date
    const today = new Date();

    // Get yesterday's date
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);


    // Compare year, month, and day of both dates
    const isToday = 
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
    
    const isYesterday = 
        date.getFullYear() === yesterday.getFullYear() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getDate() === yesterday.getDate()

    // Create a date object for 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    // 10 days
    const tenDaysAgo = new Date();
    tenDaysAgo.setDate(today.getDate() - 10);
    // Function to check if two dates are the same day
    function isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }
    // Check if the inspected date falls within the range of last 10 days
    // and is not equal to today or yesterday
    const isWithinLast10Days = date >= tenDaysAgo &&
                            date < yesterday &&
                            !isSameDay(date, today) &&
                            !isSameDay(date, yesterday);

    // 30 days 
    const isWithinLast30Days = date >= thirtyDaysAgo &&
                            date < tenDaysAgo &&
                            !isSameDay(date, today) &&
                            !isSameDay(date, yesterday) &&
                            !isWithinLast10Days;
    
    var topPosition = '-5pt'
    if (searchMode == "semantic" || searchMode == "similar") {topPosition = '11pt'}

    return (
        <>
            <Badge pill
                text="white"
                bg="teal"
                style={{
                    position: 'absolute',
                    top: topPosition,
                    right: '-2px',
                    backgroundColor: (isToday && '#e91e63') || (isYesterday && '#9c27b0') || (isWithinLast10Days && '#673AB7') || (isWithinLast30Days && '#03a9f4'),
                    // backgroundColor: isYesterday && '#9c27b0', // 9c27b0
                }}>
                {isToday && "New: Today"}
                {isYesterday && "New: Yesterday"}
                {isWithinLast10Days && "Fresh: < 10 days"}
                {isWithinLast30Days && "Fresh: < Month"}
                </Badge>
        </>
    );
  }


export default DateBadge;
