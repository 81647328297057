import { useState } from 'react';
import { Range } from 'react-range';

function CoupleSlider({values, setValues}) {
  return (
    <div style={{ width: '220px', margin: '0px auto' }}>
      <output style={{ marginTop: '10px', display: 'block' }}>
        <span className='text-gray'>Years Range: {values[0]} - {values[1]}</span>
      </output>
      <Range
        step={1}
        min={2007}
        max={2023}  // TODO: make this programmatic 
        values={values}
        onChange={(values) => setValues(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '100%',
              backgroundColor: '#ccc',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '14px',
              width: '14px',
              backgroundColor: '#333',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #aaa',
            }}
          ></div>
        )}
      />
      
    </div>
  );
}

export default CoupleSlider;
