import Carousel from 'react-bootstrap/Carousel';
import Pill from '../elements/Pill';

import { Container, Row, Col } from "react-bootstrap";


function Intro5() {
  return (

    <>
    
         
          <div className="intro-container-2">


        <Container>
          <Row>
            <Col md={12}>
              <div className="intro-container-3">

              <p>
                <span style={{fontSize: '32pt', color: '#fff' }} >
                {/* <span style={{fontSize: '46pt', color: '#fff', fontFamily: 'fangsong', lineHeight: 'initial'}} > */}
              AI-powered Search Engine<br /> for Scientific Papers
              </span>

              
              <span  style={{color: '#fff', fontSize: '14pt'}}>
                <br /><i>
                  Supersymmetry is a <span className="lime"> semantic search engine for ArXiv papers</span>  enhanced with <span className="lime">GPT and (now) Claude</span>
                  </i>
              </span>

              </p>

              <br />

              <p><span className="into-text-small-headers" >
              Semantic & Similarity Searches
              </span>
              <span  className="intro-text">
                <br />
                Semantic Search is <span className="lime">based on the meaning of a paper</span> which is encoded using GPT embeddings. <br />
                This produces better results and <span className="lime">could uncover rare papers </span>traditional search methods might miss.
                <br />
                  Use the 'Find Similar' feature on any paper to <span className="lime">discover all papers most similar</span> to it!
              </span>
              </p>

            
              {/* <p><span className="into-text-small-headers" >
                Similarity Search
                </span>
                <span className="intro-text">
                  <br />
                  Use the 'Find Similar' feature on any paper to <span className="lime">discover all papers most similar</span> to it!
                </span>
              </p>
  */}
              <p><span className="into-text-small-headers" >
              <span style={{color:"#25cff2"}}>
              GPT 
              </span>
              <span style={{color:"#FFF"}}>
               +  
              </span>
              
              <span style={{color:"#cc785c"}}>
                Claude
                </span> <Pill color="#E91E63" text="NEW"/>
              </span>
              <span className="intro-text">
                <br />Generate <span className="lime">paper summaries</span> using either GPT or Claude (Just added: Sep 6th, 2023)
              </span>
              </p> 

            </div>
            </Col>
          </Row>
        </Container>



          </div> 



    </>

  );
}

export default Intro5;