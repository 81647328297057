import React from 'react';
import Button from 'react-bootstrap/Button';

import './styles/styles.css';
import { ImPaintFormat } from 'react-icons/im';

const Pill = ({ href = null, fontSize = '10pt', color, text, onClick, variant = 'primary', ...props }) => {

    const style = {
        borderRadius: '4px',  // makes the button looks like a pill
        padding: '1px 8px',  // adjust padding as needed
        fontSize: fontSize,
        fontWeight: '600',
        margin: '4pt 1pt',
        backgroundColor: color, //,
        border: '0',
        color: '#fff',
        marginBottom: '7pt',
        textAlign: 'left',
    };

    const className = href == null ? "disabled-button" : "";


    return (
        <>
        <a href={href} className={className} target="_blank" rel="noopener noreferrer">
            <Button 
                style={style}
                className={className} 
                {...props}>
                {text}
            </Button>
        </a>
    </>
    )
};

export default Pill;
