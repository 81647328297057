import React from 'react';
import Button from 'react-bootstrap/Button';

import './styles/styles.css';

const PillButton = ({ className, text, onClick, variant = 'primary', ...props }) => {



    const style = {
        borderRadius: '4px',  // makes the button looks like a pill
        padding: '0px 6px',  // adjust padding as needed
        fontSize: '9pt',
        fontWeight: '600',
        margin: '2pt 2pt',
        backgroundColor: '#f8f9fa',
        border: '0',
        color: '#535353'
    };

    return (
        <Button 
            variant='light'
            // className="hover-effect"
            // style={style}
            className={className} 
            onClick={onClick} {...props}>
            {text}
        </Button>
    );
};

export default PillButton;
