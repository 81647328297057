
import { useEffect, useRef, useState } from "react";
import DOTS from "vanta/dist/vanta.dots.min";

import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Routes, Route} from 'react-router-dom'

import MainLayout from './pages/MainLayout'
import SearchPage from "./pages/SearchPage"
import QuadrantLayout from "./pages/QuadrantLayout";
import IntroLayout from "./pages/IntroLayout";
import Features from "./pages/Features";
import AboutProject from "./pages/AboutProject";
import Browse from "./pages/Browse";
import SearchBrowsePage from "./pages/SearchBrowsePage";
import Explain from "./pages/Explain";
import Explore from "./pages/Explore";
import ContactMe from "./pages/ContactMe";
import Latest from "./components/Latest";


// import PageBrowse from "./pages/PageBrowse";

function App() {

  const myRef = useRef(null);   
  const [vantaEffect, setVantaEffect] = useState(0);

  function vantaOn() {
    setVantaEffect(
      DOTS({
        el: myRef.current,
        mouseControls: false,
        touchControls: false,
        gyroControls: false,
        minHeight: 100.00,
        minWidth: 100.00,
        scale: 0.5,
        scaleMobile: 1.00,
        color: 0x20caff,
        color2: 0x20d7ff,
        backgroundColor: 0xc1c2f,
      }))
  }

   function navigateVanta() {
      if (window.location.pathname === '/') {
          vantaOn();
        } else {
          // vantaEffect.destroy();
          setVantaEffect(0);
        }
   }

   function navigateEffectsNavbar() {
    const navbar = document.querySelector('.navbar');
      if (window.location.pathname === '/') {
        navbar.classList.remove('navbar-background');
      } else {
        navbar.classList.add('navbar-background');
      }
   }
   function navigateEffectsBG() {
      const contentRow = document.getElementById('contentRow');
      
      if (window.location.pathname === '/') {
        contentRow.classList.remove('content-background-dark');
        contentRow.classList.remove('content-background-reg');
      }
      else if (['/search', '/browse'].includes(window.location.pathname)) { 
        contentRow.classList.add('content-background-reg');
      }
      else {
        contentRow.classList.add('content-background-dark');
      }
   }
   useEffect(() => {

    console.log("* ENV info *")
    console.log(process.env)

    navigateEffectsBG();
    navigateEffectsNavbar();
    navigateVanta();

    const navbar = document.querySelector('.navbar');
    navbar.classList.add('navbar-background');
    }, []);

    return (
      <BrowserRouter>
        <div className="app">
            <header className="app__header" ref={myRef}>
                <Routes>
                    <Route path="/" element={<MainLayout />} >
                      <Route index element={<IntroLayout />} />
                      {/* <Route path="browse" element={<PageBrowse />} /> */}
                      {/* <Route path="search" element={<SearchPage />} /> */}
                      <Route path="search" element={<Latest />} />
                      <Route path="explain" element={<Explain />} />
                      <Route path="explore" element={<Explore />} />
                      <Route path="contact" element={<ContactMe />} />
                      <Route path="features" element={<Features />} />
                      {/* <Route path="faq" element={<FAQ />} /> */}
                      {/* <Route path="aboutme" element={<AboutMe />} /> */}
                      {/* <Route path="contact" element={<ContactMe />} /> */}
                      {/* <Route path="support" element={<SupportProject />} /> */}
                      <Route path="project" element={<AboutProject />} />
                      {/* <Route path="pagination" element={<TestPagination />} /> */}
                    </Route>

                </Routes>
            </header>
        </div>
      </BrowserRouter>
    );
}

export default App;