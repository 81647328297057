import React, { useEffect, useState } from 'react';

const MathComponent = ({ mathExpression }) => {
  const [hasError, setHasError] = useState(false);
  
  useEffect(() => {
    try {
      // Typeset the current component whenever it re-renders
      if (window.MathJax) {
        window.MathJax.typeset();
      }
    } catch (error) {
      console.error("MathJax error:", error);
      setHasError(true); // Set the error state if MathJax throws an error
    }
  }, [mathExpression]); // Re-run the effect when mathExpression changes
  
  if (hasError) {
    return <span> [Error rendering math expression] </span>;
  }

  return (
    <span>
      {/* The "tex" tells MathJax this is LaTeX format */}
      <span className="math-tex">{`\\(${mathExpression}\\)`}</span>
    </span>
  );
}

export default MathComponent;
